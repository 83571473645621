import { Component, ComponentFactoryResolver, OnInit, ViewContainerRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router'
import { SystemComponent } from './sistemas/system.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from '../settings/settings.service';




@Component({
  selector: 'app-home-lemu',
  templateUrl: './home-lemu.component.html',
  styleUrls: ['./home-lemu.component.less']
})
export class HomeLemuComponent implements OnInit {

  public systems:any=[];

  @ViewChild('parent', { read: ViewContainerRef }) container!: ViewContainerRef;

  constructor(public snackBar: MatSnackBar, public settings: SettingsService, private router: Router, private _cfr: ComponentFactoryResolver) { }

  ngOnInit() {
    this.verify();
    this.get_personal_data();
    
  }

  verify()
  {
    if (localStorage.getItem("token") == null )
    {
     this.router.navigate(['login']);
    }
  }

  logout()
  {
    localStorage.clear()
    this.router.navigate(['login']);
  }


  user:any="";
  avatar:any=null;
  has_avatar:boolean=false;
  loading:boolean=false;
  public async get_personal_data() {
    this.loading = true;
    let resultado:any = (await this.settings.get_query(1, 'user/'))   
    let body_data=resultado;

    if(!body_data['error']){

      console.log(body_data)

      let roles = body_data.user.roles;

      this.user = body_data.user.first_name + ' ' + body_data.user.last_name;
      this.avatar = body_data.user.avatar;

      if(body_data.user.avatar == null || body_data.user.avatar == "" || body_data.user.avatar == undefined){
        this.has_avatar = false
      }else{
        this.has_avatar = true;
      }

      let aux:any[] = []

      for (let i=0; i < roles.length; i++)
      {
        for (let j=0; j < roles[i].systems.length; j++)
        {
          let s = roles[i].systems[j]
          
          if(aux.some(element => element.id === s.id))
          {
            console.log("found")
          } 
          else
          {
            aux.push(s)
          }
        }
      }


      for (let i=0; i < aux.length; i++)
      {
        var comp = this._cfr.resolveComponentFactory(SystemComponent);
        var dComponent = this.container.createComponent(comp); 
        dComponent.instance.name = aux[i].name;
        dComponent.instance.url = aux[i].url;
        dComponent.instance.initials = aux[i].short;
     }

     this.loading = false


    }else{
      this.loading = false
      localStorage.clear();
      this.router.navigate(['login']);
    }
  }


}

