import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '../../settings/settings.service';

@Component({
    selector: 'app-log-system',
    templateUrl: './system.component.html',
    styleUrls: ['./system.component.less']
})

export class SystemCACA implements OnInit {

    @Input() name:any;
    @Input() url:any;
    @Input() initials:any;

    constructor(){

    }

    ngOnInit(): void {
        
    }


    navegar()
    {
        if (this.initials == "Ve")
        {
            location.replace("http://18.219.22.195/login/?email="+localStorage.getItem("email")+"&password="+localStorage.getItem("xrp"));
        }
        else
        {
            if(this.name=="Ventas" && localStorage.getItem("role")=="admi")
            {
                location.replace(SettingsService.lemu_base + this.url + "/#/home/admin");
            }
            else
            if (this.initials == "Tb")
                location.replace(SettingsService.lemu_base + this.url + "");
            else
                location.replace(SettingsService.lemu_base + this.url + "/#/home"); 


                

            
            if(this.name=="Cobranza" && (localStorage.getItem("role")=="ecom" || localStorage.getItem("role")=="eneg" ) )
                location.replace(SettingsService.lemu_base + this.url + "/#/home/cartera");

        }
    }


}

