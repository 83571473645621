import { Component, OnInit,  Output, EventEmitter, Input } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from '../../settings/settings.service';



@Component({
    selector: '.col-lg-3 .col-md-4 .col-sm-12 .col-xs-12',
    templateUrl: './system.component.html',
    styleUrls: ['./system.component.less']
})

@Injectable()
export class SystemComponent  implements OnInit {
    public name:any;
    public initials:any;
    public url:any;

    constructor( public snackBar: MatSnackBar, public settings: SettingsService, private httpj: HttpClient) 
    {
       
    }

    navegar()
    {
        if (this.initials == "Ve")
        {
            location.replace("http://18.219.22.195/login/?email="+localStorage.getItem("email")+"&password="+localStorage.getItem("xrp"));
        }
        else
        {
            if(this.name=="Ventas" && localStorage.getItem("role")=="admi")
            {
                location.replace(SettingsService.lemu_base + this.url + "/#/home/admin");
            }
            else
            if (this.initials == "Tb")
                location.replace(SettingsService.lemu_base + this.url + "");
            else
                location.replace(SettingsService.lemu_base + this.url + "/#/home"); 


                

            
            if(this.name=="Cobranza" && (localStorage.getItem("role")=="ecom" || localStorage.getItem("role")=="eneg" ) )
                location.replace(SettingsService.lemu_base + this.url + "/#/home/cartera");

        }
    }

    ngOnInit() 
    {

    }
}
