
<div class="box-container animate__animated animate__fadeIn">
    <div class="d_system" (click)="this.navegar();"> 
        <div class="lemu_initials">
            :{{ this.initials }}  
        </div>

        <div style="margin-top: 10px;margin-bottom: 10px;"></div>

        <div class="lemu_name"> 
            {{this.name}} 
        </div>
    </div>
</div>

