import { Component, ComponentFactoryResolver, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from '../settings/settings.service';



@Component({
  selector: 'app-home-lemu',
  templateUrl: './home-lemu.component.html',
  styleUrls: ['./home-lemu.component.less'],
})


export class HomeLemuComponent implements OnInit {

  public systems:any=[];


  constructor(public snackBar: MatSnackBar, public settings: SettingsService, private router: Router) { }

  ngOnInit() {
    this.verify();
    this.get_personal_data();
    
  }

  verify()
  {
    if (localStorage.getItem("token") == null )
    {
     this.router.navigate(['login']);
    }
  }

  logout()
  {
    localStorage.clear()
    this.router.navigate(['login']);
  }


  user:any="";
  avatar:any=null;
  has_avatar:boolean=false;
  loading:boolean=false;
  aux:any[]=[];
  public async get_personal_data() {
    this.loading = true;
    let resultado:any = (await this.settings.get_query(1, 'user/'))   
    let body_data=resultado;

    if(!body_data['error']){


      let roles = body_data.user.roles;

      this.user = body_data.user.first_name + ' ' + body_data.user.last_name;
      this.avatar = body_data.user.avatar;

      if(body_data.user.avatar == null || body_data.user.avatar == "" || body_data.user.avatar == undefined){
        this.has_avatar = false
      }else{
        this.has_avatar = true;
      }

      this.aux = []

      for (let i=0; i < roles.length; i++)
      {
        for (let j=0; j < roles[i].systems.length; j++)
        {
          let s = roles[i].systems[j]
          
          if(this.aux.some(element => element.id === s.id))
          {
            console.log("found")
          } 
          else
          {
            this.aux.push(s)
          }
        }
      }

      console.log("AUX", this.aux)



     this.loading = false;



    }else{
      console.error("error")
      this.loading = false
      localStorage.clear();
      this.router.navigate(['login']);
    }
  }


}

