

<div class="box-container animate__animated animate__flipInX">
    <div class="d_system"> 
        <div class="lemu_initials" (click)="this.navegar();">
            :{{ this.initials }}
        </div>

        <div style="margin-top: 10px;margin-bottom: 10px;"></div>

        <div class="lemu_name"> 
            {{this.name}} 
        </div>
    </div>
</div>

