<section class="area-login animate__animated animate__fadeIn">
    <h1>Recuperación</h1>
    <mat-card class="login">

        <mat-card-content style="width: -webkit-fill-available;">

          <div style="text-align: center;">
          <img src="../../assets/img/simpli-azul-rosa.svg" style="height: 100px;margin-top: 20px;margin-bottom: 20px;"/>

          <!-- <img src="../../assets/img/simplilogo_old.svg" style="height: 40px;margin-top: 20px;margin-bottom: 20px;margin-right: 18px;"/> -->
          </div>
  
        <form [formGroup]="valForm"  (submit)="submitForm($event, valForm.value)" class="animate__animated animate__fadeIn">
  
          <mat-form-field appearance="fill" color="primary">
              <mat-label>Correo Electrónico</mat-label>
              <input formControlName="email" matInput type="email" placeholder="Correo Electrónico">
              <mat-icon style="color:var(--simpli-secondary);" color="primary" matSuffix>email</mat-icon>
              <mat-hint>Introduce tu correo electrónico</mat-hint>
              <mat-error *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">
                  Email es <strong>requerido</strong>
              </mat-error>
        
              <mat-error *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">                                          Debe ser un correo electrónico valido
              </mat-error>
          </mat-form-field>
  
  
          <div class="btn-container">
              <button class="primary-button-log" [disabled]="disabled" mat-flat-button type="submit" color="primary">
                <mat-label *ngIf="disabled == false">Recuperar Contraseña</mat-label>
                <span *ngIf="disabled == true"><i class="fa-solid fa-spinner fa-spin-pulse" style="color:var(--simpli-secondary);font-size: 18px;"></i></span>
              </button>
          </div>
  
  
  
        </form>
  
        <div class="center-div">
          <a mat-button routerLink="/login" style="color:var(--simpli-secondary);">Volver</a>
        </div>

      </mat-card-content>

      </mat-card>
  </section>