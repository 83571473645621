import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogErrors } from './dialog-errors/dialog-errors';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from "../environments/environment"

declare var $ :any;

@Injectable({
  providedIn: 'root'
})
export class SettingsService {



  public static API_ENDPOINT:string = environment.API_ENDPOINT;
  public static lemu_base:string = environment.lemu_base;
  public static WS_URL:string = environment.WS_URL;

 
  get_query(http: number, query: string, value: any = {}, wo_options: boolean = false) {
    const endpoint = `${SettingsService.API_ENDPOINT}${query}`
    const token = this.get_token()
    const headers= new HttpHeaders()
    .set( "Authorization", "JWT " + token )
    let req:any = new Observable()    

    switch (http) {
      case 1:
        req = this.http.get(endpoint);
        if (!wo_options) {
          req = this.http.get(endpoint, { 'headers': headers });
        }
        break;
      case 2:
        req = this.http.post(endpoint, value);
        if (!wo_options) {
          req = this.http.post(endpoint, value, { 'headers': headers });
        }
        break;
      case 3:
        req = this.http.put(endpoint, value);
        if (!wo_options) {
          req = this.http.put(endpoint, value, { 'headers': headers });
        }
        break;
      case 4:
        req = this.http.delete(endpoint, { 'headers': headers });
        break;
      default:
        req = this.http.get(endpoint);
        if (!wo_options) {
          req = this.http.get(endpoint, { 'headers': headers });
        }
        break;
      }

    return new Promise((resolve, reject) => { 
      req.subscribe(
        (data: any) => { 
          resolve(data); 
        }, (error: any) => { 


          if(error.status == 401 || error.status == 403){
            console.log("error deseado")
            this.alertTimeout("<div>Simpli Security<div><br><br><div><small>El Token venció, Se necesita volver a ingresar sesión<small><div><br><small>Este mensaje se cerrará automáticamente<small>",5000)

            localStorage.clear();
            this.router.navigate(['login']);
          }

          reject(error);
        })
    });
  };

 alertTimeout(mymsg:any,mymsecs:any)
  {
   var myelement:any = document.createElement("div");
  myelement.setAttribute("style","background-color: white;color:black; width: 450px;height: 200px;position: absolute;top:0;bottom:0;left:0;right:0;margin:auto;border: 4px solid black;font-family:arial;font-size:25px;font-weight:bold;display: flex; align-items: center; justify-content: center; text-align: center;");
   myelement.innerHTML = mymsg;
   setTimeout(function(){
    myelement.parentNode.removeChild(myelement);
   },mymsecs);
   document.body.appendChild(myelement);
  }

  manage_errors(status:any, view:any, body:any, show_dialog_errors=true) {
    switch (status) {
      case 400:
        if (show_dialog_errors) {
          this.show_errores(body, view);
        }
      break;
      case 404:
        this.snackBar.open("Si tienes problemas para ingresar comunícate con el área de soporte o tu ejecutivo", "Aceptar", {
          duration: 20000,
          panelClass: "simpli-snack"
        });
      break;
      case 500:
        this.snackBar.open("Ocurrió un error, intenta de nuevo", "Aceptar", {
          duration: 20000,
          panelClass: "simpli-snack"
        });
        this.send_mail_500(view, body);
      break;
      default:
        
      break;
    }
  }

  async send_mail_500(view:any, body:any) {
    try {
      var value = {
        "view": view,
        "status": body.status,
        "statusText": body.statusText,
        "type": body.type,
        "url": body.url,
        "body": body._body,
      }
      const bd: any = await this.get_query(2, 'cliente/alert-fail/', value, true);
      var body_data: any = JSON.parse(bd._body);

      switch (bd.status) {
        case 200:

        break;
        default:

        break;
      }

    } catch (bd: any) {

    }
  }

  show_errores(bd:any, view:any) {
    var body_data: any = JSON.parse(bd._body);
      var body_errors = body_data.error.errors;
      var keys = Object.keys(body_data.error.errors);
      var errores = []
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        const error = body_errors[element];
        for (let index = 0; index < error.length; index++) {
          errores.push(error[index])          
        }
      }
      this.dialogo_errores("Información de errores",view,"Advertencia",view,"error","#fba75ffa",errores);
  }

  dialogo_errores(title:any, subtitle:any, mensajeprincipal:any, mensajenegrita:any, icon:any, coloricon:any, errores:any){
    const dialogRef = this.dialog.open(DialogErrors,{
      width:'700px',
      autoFocus: false,
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.subtitle = subtitle;
    dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
    dialogRef.componentInstance.mensajenegrita = mensajenegrita;
    dialogRef.componentInstance.icon = icon;
    dialogRef.componentInstance.coloricon = coloricon;
    dialogRef.componentInstance.errores = errores;


    dialogRef.afterClosed().subscribe((result:any) => {

    });

  }

  verify_out() {
    if (localStorage.getItem("token") != null) {
      this.router.navigate(['home']);
    }
  }

  get_token(sw = null) {
    return localStorage.getItem("token");
  }

  empty_local_data() {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    this.router.navigate(['login']);
  }

  set_initial_overflow() {
    $(window).scrollTop(0);
  }

  constructor(private router: Router, private http: HttpClient, public snackBar: MatSnackBar, public dialog: MatDialog) {

  }
}
