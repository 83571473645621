import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';



@Component({
    selector: '.col-lg-3 .col-md-4 .col-sm-12 .col-xs-12',
    template: `<div class=""><div class="d_system" style="" (click)="this.navegar();"> 
        <span class="lemu_initials">:{{ this.initials }}  </span>
        <span class="lemu_name"> {{this.name}} </span>
    </div></div>`
})

@Injectable()
export class SystemComponent  implements OnInit {
    public name:any;
    public initials:any;
    public url:any;

    constructor(public settings: SettingsService) 
    {
       
    }

    navegar()
    {
        if (this.initials == "Ve")
        {
            location.replace("http://18.219.22.195/login/?email="+localStorage.getItem("email")+"&password="+localStorage.getItem("xrp"));
        }
        else
        {

            if(this.name=="Ventas" && localStorage.getItem("role")=="admi")
            {
                location.replace(SettingsService.lemu_base + this.url + "/#/home/admin");
            }
            else
            
            if(this.name=="Cobranza" && (localStorage.getItem("role")=="ecom" || localStorage.getItem("role")=="eneg" ) )
                location.replace(SettingsService.lemu_base + this.url + "/#/home/cartera");

        }
    }

    ngOnInit() 
    {

    }
}
