import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less'
})
export class AppComponent implements OnInit{
  title = 'lemu-login';


  constructor(private router:Router){

  }


  ngOnInit(): void {
    this.checkToken();
  }

  loading = true;
  async checkToken() {
    this.loading = true;
    const token = localStorage.getItem('token');
    if (token) {

      setTimeout(()=>{
         this.router.navigate(['/home'], { replaceUrl: true });
         this.loading = false;
      }, 1500);

    } else {
      this.router.navigate(['/login'], { replaceUrl: true });
       this.loading = false;
    }

  }
}
