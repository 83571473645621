<section class="area-login animate__animated animate__fadeIn">
  <div class="row row_main animate__animated animate__fadeIn" style="width: 100%;">

      <div class="col-lg-9 col-md-9 col-sm-9 col-xs-11 col-top animate__animated animate__fadeIn" style="margin:0 auto;margin-bottom: 10%;">

        <div class="row" style="margin-top: 1%;">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;margin-top: 20px;position: relative;">


                <div style="margin-bottom: 70px;text-align: center;">

                  <img  *ngIf="has_avatar == true" class="ejecutive_img animate__animated animate__fadeIn" 
                  style="margin-bottom: -27px; background: linear-gradient(45deg,#F8C7EA,#E176BB);"
                  [src]="avatar">
    
                  <div *ngIf="has_avatar == false" 
                    style="border: 4px solid #F8C7EA;margin-bottom: -29px !important;"  
                    class="my-avatar animate__animated animate__fadeIn"><span style="margin-top: -27px;" id="avatar">
                      {{user.charAt(0)}}</span>
                  </div>

                </div>

                <h1 class="titulo-simpli">Bienvenido(a) {{user}}</h1>
                <small class="subtitulo-simpli">Seleccione una opción para navegar</small>
              </div>
        </div>

        <div class="row animate__animated animate__fadeIn" style="margin-top: 100px" *ngIf="loading == true">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="sinRegistros" style="text-align: center;background: none !important;">
            <small  class="small-sinregistros" style="font-size: 15px; margin-top: 20px;">
              <div style="text-align: center">
                <i
                  style="font-size: 50px; color: gray; margin-bottom: 20px"
                  class="fa-solid fa-spinner fa-spin-pulse"
                ></i>
              </div>
            </small>
          </div>
        </div>
      </div>

        <div class="row animate__animated animate__fadeIn" style="margin-top: 2%;">
          <span #parent class="animate__animated animate__fadeIn"></span>

          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 animate__animated animate__fadeIn">

            <div class="box-container animate__animated animate__fadeIn" (click)="logout()" style="background-color: var(--simpli-secondary);">
              <div class="d_system"> 

                  <div class="lemu_initials" style="position: relative;">
                    <span>
                      <i class="fa-duotone fa-arrow-right-from-bracket" style="color:white;"></i>
                    </span>

                    <div style="margin-top: 3px;"></div>

                  </div>
                  <div class="lemu_name" style="color:white;"> 
                      Cerrar Sesión
                  </div>
              </div>
            </div>

          </div>

        </div>

      
      </div>


  </div>
</section>